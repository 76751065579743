<template>
  <div class="admin-message-wrapper">
    <!-- <el-card>
      <el-tabs type="border-card">
        <el-tab-pane label="用户信息审核">用户</el-tab-pane>
        <el-tab-pane label="配置管理">配置管理</el-tab-pane>
        <el-tab-pane label="角色管理">角色管理</el-tab-pane>
      </el-tabs>
    </el-card> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      msgTitle: '',
    };
  },
};
</script>

<style></style>
